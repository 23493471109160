// 我的工单列表表头
export const ticketInfoColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '工单名称',
      align: 'left',
      width: 200,
      ellipsis: true,
      dataIndex: 'ticket.title'
    },
    /*{
      title: '所属部门',
      width: 200,
      align: 'center',
      dataIndex: 'department.name'
    },*/
    {
      title: '工单来源',
      width: 100,
      align: 'center',
      dataIndex: 'ticket.ticketSource',
      scopedSlots: { customRender: 'ticket.ticketSource' }
    },
    {
      title: '创建人',
      width: 120,
      align: 'center',
      dataIndex: 'ticket.creater'
    },
    {
      title: '创建时间',
      width: 200,
      align: 'center',
      dataIndex: 'dateCreated'
    },
    {
      title: '指派人',
      width: 120,
      align: 'center',
      dataIndex: 'ticket.assignUser.name',
      customRender: (text, record) => {
        const time = `${record.ticket.assignUser ? record.ticket.assignUser.name : '~'}` //es6写法
        return time
      },
    },
    {
      title: '状态',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: '开始处理时间',
      width: 180,
      align: 'center',
      dataIndex: 'ticket.startProcessTime',
      scopedSlots: { customRender: 'ticket.startProcessTime' }
    },
    {
      title: '处理完成时间',
      width: 180,
      align: 'center',
      dataIndex: 'ticket.endProcessTime',
      scopedSlots: { customRender: 'ticket.endProcessTime' }
    },
    {
      title: '描述',
      minwidth: 200,
      align: 'left',
      ellipsis: true,
      dataIndex: 'ticket.description'
    },
    {
      title: '操作',
      width: 140,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

//
export const processStatusList = function () {
  return [
    { value: 'UN_PROCESS', label: '未处理' },
    { value: 'PROCESSING', label: '处理中' },
    { value: 'PROCESSED', label: '已处理' }
  ]
}

export const ticketTypeList = function () {
  return [
    { value: 'INSPECT', label: '巡检' },
    { value: 'REPORT', label: '上报' },
    { value: 'CAR', label: '车辆' },
    { value: 'OTHER', label: '其他' }
  ]
}

// 工单来源
export const tickSourceList = function () {
  return [
    { value: 'AUTO', label: '自动生成' },
    { value: 'MANUAL', label: '人工创建' },
    { value: 'REPORT', label: '电话调度' }
  ]
}